<template>
  <b-overlay :show="isLoading">
    <b-container>
      <b-col>
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h1 class="font-weight-bolder text-primary">Delivery Tracking</h1>
          </div>
          <div class="bg d-flex align-items-center rounded">
            <div class="bg-primary py-1 px-1 text-center rounded-left">
              <p class="rounded-left bg-primary text-white m-0 p-0">
                Delivery ID
              </p>
            </div>
            <p
              class="rounded-right box-shadow-1 py-1 px-1 bg-blue font-weight-bolder m-0 p-0"
            >
              {{ this.$route.params.id }}
            </p>
          </div>
        </div>
      </b-col>
      <b-col>
        <app-collapse accordion>
          <app-collapse-item
            v-for="(item, index) in orders"
            :key="index"
            class="my-2"
            title=""
            :subtitle="''"
            variant="warning"
          >
            <template #header>
              <b-button variant="gradient-primary">
                {{ `Order ID: ${item.id}` }}
              </b-button>
            </template>
            <TrackOrderID :prop-order="item" :prop-is-loading="isLoading" />
          </app-collapse-item>
        </app-collapse>
      </b-col>
    </b-container>
    <div class="d-flex justify-content-center align-items-center mt-5">
      <b-button
        variant="gradient-primary"
        class="box-shadow-1 px-5 mx-2"
        @click="back"
      >
        Back
      </b-button>
    </div>
  </b-overlay>
</template>
<script>
import AppCollapse from '@core/components/app-collapse/AppCollapse.vue';
import AppCollapseItem from '@core/components/app-collapse/AppCollapseItem.vue';
import TrackOrderID from './TrackOrderID.vue';

export default {
  components: {
    AppCollapse,
    AppCollapseItem,
    TrackOrderID,
  },

  data() {
    return {
      orders: null,
      isLoading: false,
    };
  },
  created() {
    this.getOrders();
  },
  methods: {
    async getOrders() {
      this.isLoading = true;
      const response = await this.$http.get(
        `get_route_orders?route_id=${this.$route.params.id}`
      );
      if (response.data.status === false) {
        this.$swal({
          title: 'Error',
          text: response.data.message,
          icon: 'error',
          showCancelButton: false,
          confirmButtonText: 'Go back',
          customClass: {
            confirmButton: 'btn-danger',
          },
        }).then((result) => {
          if (result.isConfirmed) {
            this.$router.go(-1);
          }
        });
      }

      this.isLoading = false;
      this.orders = response.data.data;
    },
    back() {
      this.$router.replace('/track-and-trace');
    },
  },
};
</script>
